'use strict';

const _TEMPLATES_DIR = 'app-devices-manager/templates/';
const pathSuffix = '/devices-manager/';

angular.module('managerApp.devicesManager', ['ngRoute']).config(($routeProvider) => {
    $routeProvider
            .when('/devices-manager/demandes-paiement-en-especes', {
                templateUrl: _TEMPLATES_DIR + 'payment.requests.html',
                controller: 'DevicesManagerController',
                resolve: {
                    _templatesDir: () => _TEMPLATES_DIR,
                    _type: () => 'payment-requests'
                }
            }).when('/logout',
            {
                name: 'logout',
                referrer: '/',
                template: '',
                controller: function ($scope, $location, $mdDialog, $mdToast, Auth) {
                    Auth.logout();
                    $mdToast.hide();
                    $mdDialog.cancel();
                    setTimeout(() => {
                        $location.path('/devices-manager/login');
                        $scope.$apply();
                    });
                }
            });

});